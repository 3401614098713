<template>
  <div class="wrapper">
    <div class="mb-4">
      <h3>리더십 문항 관리​</h3>
    </div>

    <article>
      <div class="white-box">
        <table class="info mb-4">
          <tbody>
            <tr>
              <td class="p-2 label text-center">진단 템플릿명</td>
              <td class="p-2">
                <b-form-input v-model="process.questionData.question_group_name" type="text" class="input"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div class="d-flex justify-content-end gap-2 mb-2">
            <b-button @click="process.RemoveSection()" variant="outline-danger">진단영역 삭제</b-button>
            <b-button @click="process.addSection()" variant="outline-primary">진단영역 추가</b-button>
            <b-button @click="process.setExcelModal()" variant="outline-success">엑셀 일괄입력</b-button>
          </div>

          <div v-for="section in process.questionData.question_section_list" :key="section.idx" class="input-wrapper">
            <table :class="{ 'mb-2': !section.collapse }" class="info">
              <tbody>
                <tr>
                  <td class="p-2 label text-center">진단영역명</td>
                  <td class="p-2">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <b-form-input v-model="section.section_name" type="text" class="input"></b-form-input>
                      <div :class="{ rotate180: section.collapse }" @click="process.setCollapse(section)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill cursor" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="section.collapse">
                  <td class="p-2 label text-center">설명</td>
                  <td class="p-2">
                    <b-form-textarea v-model="section.section_desc" no-resize class="input"></b-form-textarea>
                  </td>
                </tr>
                <tr v-if="section.collapse">
                  <td class="p-2 label text-center">답변 필수여부</td>
                  <td class="p-2">
                    <div class="d-flex gap-4">
                      <b-form-radio v-model="section.section_require_yn" :name="section.idx" value="Y" class="cursor">Y</b-form-radio>
                      <b-form-radio v-model="section.section_require_yn" :name="section.idx" value="N" class="cursor">N</b-form-radio>
                    </div>
                  </td>
                </tr>
                <tr v-if="section.collapse">
                  <td class="p-2 label text-center">노출 페이지</td>
                  <td class="p-2">
                    <div class="d-flex align-items-center gap-2">
                      <ul class="set-count d-flex align-items-center">
                        <li @click="process.setSectionPageNumber(section, 'minus')" class="px-2 py-1 cursor set-btn">-</li>
                        <li class="px-2 py-1">{{ section.section_page_num }}</li>
                        <li @click="process.setSectionPageNumber(section, 'plus')" class="px-2 py-1 cursor set-btn">+</li>
                      </ul>
                      <p class="text-danger">※ 진단 진행 시, 노출되는 페이지를 설정합니다.</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <ul v-if="section.collapse" class="form-list mb-4">
              <li v-for="(question, index) in section.question_list" :key="question.idx">
                <div class="d-flex">
                  <div class="p-2 label flex-none d-flex justify-content-center align-items-center bg-e9ecef">({{ index + 1 }})</div>
                  <div class="p-2 w-full">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex gap-4">
                        <b-form-radio v-model="question.question_type" :name="question.idx" value="객관식" class="cursor">객관식 유형</b-form-radio>
                        <b-form-radio v-model="question.question_type" :name="question.idx" value="주관식" class="cursor">주관식 유형</b-form-radio>
                        <b-form-radio @click="process.setLinearForm(question)" v-model="question.question_type" :name="question.idx" value="선형배율" class="cursor"
                          >선형 배율 유형</b-form-radio
                        >
                        <b-form-radio v-model="question.question_type" :name="question.idx" value="체크박스" class="cursor">체크박스 유형</b-form-radio>
                      </div>
                      <ul class="d-flex gap-2">
                        <li v-if="index !== 0" @click="process.removeQuestion(section, index)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle cursor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                          </svg>
                        </li>
                        <li @click="process.addQuestion(section, index)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle cursor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                          </svg>
                        </li>
                      </ul>
                    </div>

                    <!-- 객관식  -->
                    <div v-if="question.question_type === '객관식'" class="p-2">
                      <ul>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>질문 :</p>
                          <b-form-input v-model="question.question_title" type="text" class="input"></b-form-input>
                        </li>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>답변 수 :</p>
                          <ul class="set-count d-flex align-items-center">
                            <li @click="process.RemoveAnswer(question.question_sub_list)" class="px-2 py-1 cursor set-btn">-</li>
                            <li class="px-2 py-1">{{ question.question_sub_list.length }}</li>
                            <li @click="process.addAnswer(question.question_sub_list)" class="px-2 py-1 cursor set-btn">+</li>
                          </ul>
                        </li>
                        <li>
                          <ul>
                            <li v-for="subQuestion in question.question_sub_list" :key="subQuestion.idx" class="mb-2">
                              <div class="d-flex align-items-center gap-2">
                                <p>객관식 답변: {{ subQuestion.answer_num }}</p>
                                <b-form-input v-model="subQuestion.answer_txt" type="text" class="input"></b-form-input>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    <!-- 주관식 -->
                    <div v-if="question.question_type === '주관식'" class="p-2">
                      <ul>
                        <li class="d-flex align-items-center gap-2">
                          <p>질문 :</p>
                          <b-form-input v-model="question.question_title" type="text" class="input"></b-form-input>
                        </li>
                      </ul>
                    </div>

                    <!-- 선형배율 -->
                    <div v-if="question.question_type === '선형배율'" class="p-2">
                      <ul>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>질문 :</p>
                          <b-form-input v-model="question.question_title" type="text" class="input"></b-form-input>
                        </li>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>개수 설정 :</p>
                          <ul class="set-count d-flex align-items-center">
                            <li @click="process.RemoveAnswer(question.question_sub_list, '선형배율')" class="px-2 py-1 cursor set-btn">-</li>
                            <li class="px-2 py-1">{{ question.question_sub_list.length }}</li>
                            <li @click="process.addAnswer(question.question_sub_list, '선형배율')" class="px-2 py-1 cursor set-btn">+</li>
                          </ul>
                        </li>
                        <li>
                          <ul>
                            <li v-for="(subQuestion, index) in question.question_sub_list" :key="subQuestion.idx" class="mb-2">
                              <div v-if="index < 3" class="d-flex align-items-center gap-2">
                                <p>객관식 답변:</p>
                                <b-form-input v-model="subQuestion.answer_txt" type="text" class="input"></b-form-input>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    <!-- 체크박스 -->
                    <div v-if="question.question_type === '체크박스'" class="p-2">
                      <ul>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>중복 선택 수 설정 :</p>
                          <ul class="set-count d-flex align-items-center">
                            <li @click="process.removeDuplicateSelections(question)" class="px-2 py-1 cursor set-btn">-</li>
                            <li class="px-2 py-1">{{ question.duplicate_selections }}</li>
                            <li @click="process.addDuplicateSelections(question)" class="px-2 py-1 cursor set-btn">+</li>
                          </ul>
                        </li>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>질문 :</p>
                          <b-form-input v-model="question.question_title" type="text" class="input"></b-form-input>
                        </li>
                        <li class="d-flex align-items-center gap-2 mb-2">
                          <p>답변 수 :</p>
                          <ul class="set-count d-flex align-items-center">
                            <li @click="process.RemoveAnswer(question.question_sub_list)" class="px-2 py-1 cursor set-btn">-</li>
                            <li class="px-2 py-1">{{ question.question_sub_list.length }}</li>
                            <li @click="process.addAnswer(question.question_sub_list)" class="px-2 py-1 cursor set-btn">+</li>
                          </ul>
                        </li>
                        <li>
                          <ul>
                            <li v-for="subQuestion in question.question_sub_list" :key="subQuestion.idx" class="mb-2">
                              <div class="d-flex align-items-center gap-2">
                                <p>답변 :</p>
                                <b-form-input v-model="subQuestion.answer_txt" type="text" class="input"></b-form-input>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="text-center">
            <b-button @click="process.postQuestionData()" variant="primary"> {{ route.query.type === 'create' ? '생성' : '수정' }}하기</b-button>
          </div>
        </div>
      </div>

      <div v-if="process.isExcelModal" class="popup-wrapper">
        <div class="white-box">
          <div @click="process.setExcelModal()" class="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg cursor" viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
          <p class="title">리더십 진단 문항 일괄 등록</p>
          <input @change="event => process.changeFile(event)" type="file" class="cursor" />
          <div class="download">
            <p class="text-primary cursor mr-1" @click="process.downloadQuesForm">(예시 다운로드)</p>
            <p class="text-success cursor" @click="process.downloadQuesExample">(양식 다운로드)</p>
          </div>
          <div class="text-center">
            <b-button variant="primary" @click="process.excelFileUpdate"> 입력하기</b-button>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { onMounted } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const store = useStore();

// 1부터 10000까지의 랜덤수
// id 값 중복을 방지해서 random으로 처리
const getRandomId = () => {
  return Math.floor(Math.random() * 10000) + 1;
};

const process = reactive({
  // 로딩
  isLoading: true,
  // 보낼 parameters
  params: {
    idx: route.query.idx,
  },
  // excel modal
  isExcelModal: false,
  // excel file 정보
  excelFile: {
    fileName: '',
    file: '',
  },
  // excel modal 설정 method
  setExcelModal: () => {
    process.isExcelModal = !process.isExcelModal;
  },
  // file 변경
  changeFile: event => {
    process.excelFile.file = event.target.files[0];
  },
  // file upload
  excelFileUpdate: async () => {
    if (!process.excelFile.file) {
      alert('엑셀을 등록해주세요.');
      return;
    }
    const formData = new FormData();
    formData.append('question_file', process.excelFile.file);
    const result = await _axios.post(`/admin/question/excelData`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const { success, data, message } = result.data;

    if (success) {
      process.setExcelModal();

      process.questionData.question_section_list = data.map(item => {
        return { ...item, collapse: true };
      });
      process.excelFile.file = '';
    } else {
      alert(message);
    }
  },

  // 문항 관련 data
  questionData: {
    rowNum: '', // 순서 number
    idx: 0, // index
    question_group_paging_cnt: 0,
    question_group_name: '', // 진단 템플릿명
    section_desc: '', // 설명
    question_section_list: [
      {
        idx: getRandomId(), // idx 는 서버에서 다시 관리 함으로 random 값 으로 설정
        idx_leadership_question_main: getRandomId(), // idx 는 서버에서 다시 관리 함으로 random 값 으로 설정
        section_page_num: 1, // 노출 페이지
        section_name: '', // 진단 영역 명
        section_desc: '', // 설명
        section_require_yn: 'Y', // 답변 필수 여부
        collapse: true, // 열기
        question_list: [
          {
            idx: getRandomId(), // idx 는 서버 관리
            idx_leadership_question_section: getRandomId(), // idx 는 서버 관리
            question_num: 1, // 질문 문항 번호
            question_title: '', // 질문
            question_type: '객관식', // 질문 타입 객관식|주관식|선형배율|체크박스
            question_cmpt: '',
            question_sub_cmpt: '',
            duplicate_selections: 1, // 중복 선택 수 설정 ( 체크박스만 해당 )
            question_sub_list: [
              {
                idx: 1, // 답변 index
                idx_leadership_question: 1, // question index
                answer_num: 1, // 답변 번호
                answer_txt: '', // 답변 텍스트
              },
            ],
          },
        ],
      },
    ],
    question_cnt: 1,
  },
  // 문항 데이터 가져오는 API
  getQuestionData: async () => {
    process.isLoading = true;
    const result = await _axios.get(`/admin/question/${process.params.idx}`, {});

    const { success, data, message } = result.data;

    if (success) {
      // 열고 닫기 관리 data 추가
      data.question_section_list = data.question_section_list.map(item => {
        return { ...item, collapse: true };
      });
      process.questionData = data;
    } else {
      alert(message);
    }
    process.isLoading = false;
  },

  // 열고 닫기 처리
  setCollapse: argsSection => {
    argsSection.collapse = !argsSection.collapse;
  },

  // section 갯수 추가
  setSectionPageNumber: (item, option) => {
    if (option === 'minus') {
      if (item.section_page_num === 1) {
        item.section_page_num = 1;
      } else {
        item.section_page_num -= 1;
      }
    } else {
      item.section_page_num += 1;
    }
  },
  // 문항 추가 최대 10개
  addQuestion: (item, index) => {
    if (item.length === 10) return;

    item.question_list.splice(index + 1, 0, {
      idx: getRandomId(),
      idx_leadership_question_section: item.idx,
      question_num: index + 1,
      question_title: '',
      question_type: '객관식',
      question_cmpt: '',
      duplicate_selections: 1,
      question_sub_cmpt: '',
      question_sub_list: [{ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: 1, answer_txt: '' }],
    });
  },
  // 문항 삭제
  removeQuestion: (item, index) => {
    item.question_list.splice(index, 1);
  },
  // 답변 처리
  addAnswer: (item, type) => {
    // 선형 배율은 홀수로만 처리 가능
    if (type === '선형배율') {
      if (item.length === 9) return;
      const answerNum = item.length;
      item.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: answerNum + 1, answer_txt: '' });
      item.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: answerNum + 2, answer_txt: '' });
    } else {
      // 나머지는 10 개까지 1개씩 처리 가능
      if (item.length === 10) return;

      item.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: item.length + 1, answer_txt: '' });
    }
  },
  // 리니어를 눌렀을때, 3개로 처리하기 위해서 구성된 method
  setLinearForm: question => {
    if (question.question_sub_list.length === 1) {
      question.question_sub_list.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: 2, answer_txt: '' });
      question.question_sub_list.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: 3, answer_txt: '' });
    } else if (question.question_sub_list.length === 2) {
      question.question_sub_list.push({ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: 3, answer_txt: '' });
    }
  },

  // 답변 삭제
  RemoveAnswer: (item, type) => {
    if (item.length === 1) return;

    if (type === '선형배율') {
      if (item.length === 3) return;
      item.splice(item.length - 1, 1);
      item.splice(item.length - 1, 1);
    } else {
      item.splice(item.length - 1, 1);
    }
  },

  // 섹션 삭제
  RemoveSection: () => {
    if (process.questionData.question_section_list.length === 1) return;
    process.questionData.question_section_list.splice(process.questionData.question_section_list.length - 1, 1);
  },

  // 섹션추가
  addSection: () => {
    process.questionData.question_section_list.push({
      idx: getRandomId(),
      idx_leadership_question_main: getRandomId(),
      section_page_num: 1,
      section_name: '',
      section_desc: '',
      collapse: true,
      section_require_yn: 'Y',
      question_list: [
        {
          idx: getRandomId(),
          idx_leadership_question_section: getRandomId(),
          question_num: 1,
          question_title: '',
          question_type: '객관식',
          duplicate_selections: 1,
          question_cmpt: '',
          question_sub_cmpt: '',
          question_sub_list: [{ idx: getRandomId(), idx_leadership_question: getRandomId(), answer_num: 1, answer_txt: '' }],
        },
      ],
    });
  },
  // 중복수 선택 수 설정
  removeDuplicateSelections: item => {
    if (item.duplicate_selections === 1) return;
    item.duplicate_selections -= 1;
  },
  addDuplicateSelections: item => {
    if (item.duplicate_selections === 10) return;
    item.duplicate_selections += 1;
  },

  // 생성 및 수정 처리
  postQuestionData: async () => {
    const result = await _axios.post('/admin/question', process.questionData, {});
    const { success, message } = result.data;
    if (success) {
      alert(message);
      router.push('/QuestionManagementList');
    } else {
      alert(message);
    }
  },
  // 엑셀 양식 다운로드 처리
  downloadQuesExample: async () => {
    window.location.href = `https://kr.object.ncloudstorage.com/leadership/excel/leadershipQuesExample.xlsx`;
  },
  downloadQuesForm: async () => {
    window.location.href = `https://kr.object.ncloudstorage.com/leadership/excel/leadershipQuesForm.xlsx`;
  },
});

onMounted(() => {
  if (route.query.type === 'edit') {
    process.getQuestionData();
  }
});
</script>

<style lang="scss" scoped>
.rotate180 {
  transform: rotate(180deg);
}
.bg-e9ecef {
  background-color: #e9ecef;
}

.label {
  width: 150px;
}

.input {
  width: 70%;
}

.form-list {
  > li {
    border: 1px solid #dee2e6;

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  }
}

.set-count {
  border-radius: 10px;
  border: 1px solid #e9ecef;
  overflow: hidden;

  .set-btn {
    background-color: #e9ecef;
  }
}

.popup-wrapper {
  position: fixed;
  inset: 0;
  background: rgba($color: black, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .white-box {
    width: 500px;

    .close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 10px;
    }

    .download {
      display: flex;
      justify-content: flex-end;
    }

    .text-success {
      font-size: 14px;
      text-align: right;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .text-primary {
      margin-right: 8px;
      font-size: 14px;
      text-align: right;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>
